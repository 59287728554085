.branch_tab > .menu{
  display: flex;
  flex-wrap: wrap;
}

.branch_tab > .menu > .item{
  margin-right: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bolder !important;
}

.certificate-form > div, .certificate-form > div > div{
  opacity: 1 !important;
}

.certificate-form > div > div > i {
    opacity: 0 !important;
}

.input > input {
  height: 39px;
}