.sidemenu > .menu {
    width: auto !important;
    height: -webkit-fill-available;
}

.ui.vertical.menu>.item {
    color: white;
}

.ui.menu .active.item {
    color: #2668ce !important;
    font-weight: bold !important;
}